<template>
  <div class="Analydialog">
    <el-dialog
      :title="$t('label.report_details')"
      :visible.sync="formshowD"
      width="74.3%"
      custom-class="dialog"
      top="22vh"
      :before-close="close"
    >
      <!-- <div class="fileTitle">Aggregated forms data > Submissions</div> -->
      <div class="fileTitle">
        {{ $t("label.aggregated_formsdata") }}

        <!-- </div>
      <div class="pull-right" style="margin-bottom: 20px"> -->
        <el-button size="mini" @click="exportshow = true">{{
          $t("label.reportexport")
        }}</el-button>
        <!-- <el-button size="mini" @click="tablexport">Save as report</el-button> -->
      </div>
      <div class="tableBox">
        <table-panel
          ref="tablePanel"
          :dataList="datalist"
          :tableAttr="tablearr"
        ></table-panel>
      </div>
    </el-dialog>
    <!-- 点击Save as report出现弹框 -->
    <el-drawer
      :title="$t('vue_label_report_savereport')"
      :visible.sync="drawershow"
      direction="rtl"
      size="45%"
      ref="elDrawer"
    >
      <div class="drawerconter">
        <div class="drawbodyone" v-show="drawbodyshow">
          <p>
            {{ $t("label.report_dashboard") }}
          </p>
          <div class="drapdiv"></div>
          <p>{{ $t("label.existing_dashboard") }}</p>
          <el-radio v-model="radio" label="1">{{
            $t("label.noadd_dashboard")
          }}</el-radio>
          <br />
          <el-radio v-model="radio" label="2">{{
            $t("label.existing_dashboard")
          }}</el-radio>
          <br />
          <el-select
            v-model="value"
            clearable
            :placeholder="$t('label.report.action.addtodashboard3 ')"
            v-show="radio == '2'"
          >
            <div class="searchinput">
              <el-input
                class="search-text"
                v-model="searchValue"
                :placeholder="$t('label.searchs')"
                suffix-icon="el-icon-search"
                style="width: 98%; margin-left: 1%"
              ></el-input>
            </div>
            <el-option
              v-for="item in options"
              :key="item.label"
              :label="item.label"
              :value="item.label"
            >
            </el-option>
          </el-select>
          <el-radio v-model="radio" label="3">{{
            $t("label.report.action.addtodashboard5")
          }}</el-radio>
        </div>
        <div class="drawbodysecond" v-show="!drawbodyshow">
          <div v-show="radio == '3'">
            <h1>{{ $t("label.newdashboardpage.name") }}</h1>
            <input type="text" />
          </div>
          <p>{{ $t("label.access_report") }}</p>
          <el-radio-group v-model="radiore" @change="change">
            <el-radio label="1">{{ $t("label.private_me") }}</el-radio> <br />
            <el-radio label="2">{{ $t("label.everyone") }}</el-radio>
            <br /> </el-radio-group
          ><br />
          <el-radio-group
            v-model="radioreport"
            class="elradiogroup"
            :disabled="radiore == '1'"
          >
            <el-radio label="3">{{ $t("label.view_edit") }}</el-radio
            ><br />
            <el-radio label="4">{{ $t("label.view_only") }}</el-radio>
          </el-radio-group>
          <span>{{ $t("label.account_noordab") }}</span>
        </div>
      </div>
      <div class="drawerfoot">
        <div class="buttongroup1" v-show="drawbodyshow">
          <button @click="drawbodyshow = false" v-show="radio !== '2'">
            {{ $t("label.import.page1.nextstep") }}
          </button>
          <button class="buttwo" @click="save" v-show="radio == '2'">
            {{ $t("component.telerecord.button.save") }}
          </button>
        </div>
        <div class="buttongroup2" v-show="!drawbodyshow">
          <button
            @click="drawbodyshow = true"
            v-show="radio == '3'"
            class="buttone"
          >
            <i class="el-icon-arrow-left"></i> {{ $t("vue_label_help_back") }}
          </button>
          <button class="buttwo" @click="save">
            {{ $t("component.telerecord.button.save") }}
          </button>
        </div>
      </div>
    </el-drawer>
    <!-- export弹出框 -->
    <el-dialog
      :title="$t('label.reportexport')"
      :visible.sync="exportshow"
      width="587px"
      custom-class="dialog"
    >
      <div class="exportportion">
        <h1>{{ $t("label.emailtocloudcc.name") }}</h1>
        <input type="text" v-model="nameval" />
        <h1>{{ $t("label.file_format") }}</h1>
        <el-select v-model="exportvalue">
          <el-option v-for="item in exportoptions" :key="item" :value="item">
          </el-option>
        </el-select>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="exportshow = false">{{
          $t("label.emailsync.button.cancel")
        }}</el-button>
        <el-button type="primary" @click="exportdata">{{
          $t("label.reportexport")
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script >
import tablePanel from '@/views/marketForm/components/TablePanel/index.vue'
import * as marketChannel from "../api";//api
import axios from "@/config/httpConfig";
export default {
  name: 'analydialog',
  props: {
    formshow: {
      type: Boolean,
    },
    startdate: {
      type: String,
      default: ''
    },
    enddate: {
      type: String,
      default: ''
    },
  },
  components: {
    tablePanel
  },
  data() {
    return {
      formshowD:this.formshow,
      // tableAttr: [
      //   { schemefieldName: "percentage", schemefieldType: "PE", },
      //   { schemefieldName: "leixing", schemefieldType: "L", },


      //   {
      //     schemefieldName: "name", schemefieldType: "L",
      //   },
      //   {
      //     schemefieldName: "age", schemefieldType: "L",
      //   },
      //   {
      //     schemefieldName: "sex", schemefieldType: "L",
      //   }],
      // dataList: [{ percentage: 0.1, leixing: "广告宣传", name: "123", age: "23", sex: "nan" },
      // { percentage: 0.9, leixing: "广告宣传", name: "123", age: "23", sex: "nan" }],
      drawershow: false,
      radio: '1',
      drawbodyshow: true,
      radiore: "1",
      radioreport: '1',
      options: [{

        label: '黄金糕'
      }, {

        label: '双皮奶'
      }, {

        label: '蚵仔煎'
      }, {

        label: '龙须面'
      }, {

        label: '北京烤鸭'
      }],
      value: '',
      exportshow: false,
      exportvalue: "csv",//导出格式
      exportoptions: ["csv", "xls", "xlsx"],
      searchValue: "",//搜索内容
      tablearr: [],//表头
      datalist: [],//表的内容
      nameval: '',
    }
  },
  methods: {
    close() {
      this.formshowD = false;
      this.$emit('func', this.formshowD)
    },
    tablexport() {
      this.drawershow = true;
      this.radio = '1';
      this.radiore = "1";
      this.radioreport = '1';
    },
    change(item) {
      if (item == '1') {
        this.radioreport = '1';
      } else {
        this.radioreport = '3';
      }
    },
    exportdata() {
      let obj = {
        exportType: this.exportvalue,
        startTime: this.startdate,
        endTime: this.enddate,
        id: this.$store.state.creatform.itemDataId,
      }
      let app = JSON.stringify(obj)
      axios.post(`/form/exportSubmissions`, app, {
        // responseType: "arraybuffer",
        responseType: 'blob',
      }).then((res) => {
        let blob = new Blob([res],); //type这里表示xlsx类型
        let downloadElement = document.createElement('a');
        let href = window.URL.createObjectURL(blob); //创建下载的链接
        downloadElement.href = href;
        if (this.nameval) {
          downloadElement.download = this.nameval + "." + this.exportvalue; //下载后文件名
        } else {
          downloadElement.download = 'exportform.' + this.exportvalue; //下载后文件名
        }

        document.body.appendChild(downloadElement);
        downloadElement.click(); //点击下载
        document.body.removeChild(downloadElement); //下载完成移除元素
        window.URL.revokeObjectURL(href); //释放掉blob对象 
        this.exportshow = false
      })
    },
    save() {
      this.drawbodyshow = true;
      this.$refs.elDrawer.closeDrawer();
    },
    //弹框搜索
    searchList() {

    },
    getformdata() {
      let obj = {
        id: this.$store.state.creatform.itemDataId,
        startTime: this.startdate,
        endTime: this.enddate,
      }
      marketChannel.getFormInfo(obj).then((res) => {
        this.tablearr = [
          { schemefieldName: this.$i18n.t('label.submissions'), schemefieldType: 'L', key: 'submissions' },
          { schemefieldName: this.$i18n.t('label.views'), schemefieldType: 'L', key: 'views' },
          { schemefieldName: this.$i18n.t('label.submission_rate'), schemefieldType: 'L', key: 'submissionRate' },
        ];
        let arrydata = [];
        if (res.data.subject) {
          this.tablearr.unshift(
            { schemefieldName: this.$i18n.t('label.form_content'), schemefieldType: 'S', key: 'form' }
          )
          arrydata = [
            {
              form: res.data.subject,
              submissions: res.data.submissions,
              views: res.data.views,
              submissionRate: res.data.submissionRate
            },]

        } else {

          arrydata = [
            {
              submissions: res.data.submissions,
              views: res.data.views,
              submissionRate: res.data.submissionRate
            },]
        }
        this.datalist = arrydata;
        // this.datalist = JSON.stringify(arrydata);
        // this.tablearr = JSON.stringify(this.tablearr);
        // this.$refs.tablePanel.changeTableAttr(this.tablearr)
      })
    },
  }
}
</script>
<style lang='scss'  scoped>
.tableBox {
  margin: 20px auto;
}
.fileTitle {
  font-family: MicrosoftYaHei;
  font-size: 14px;
  color: #666666;
  letter-spacing: 0;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}
::v-deep .el-drawer {
  background: #ffffff;
  border: 1px solid #dedcda;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  border-radius: 2px;
  width: 100%;
  .el-drawer__header {
    font-family: MicrosoftYaHei-Bold;
    font-size: 18px;
    color: #3e3c3c;
    letter-spacing: 0;
    font-weight: bolder;
    width: 100%;
  }
  .drawerconter {
    border-top: 1px solid #dedcda;
    border-bottom: 1px solid #dedcda;
    height: 87%;
    .drawbodyone {
      margin-left: 2.7%;
      margin-top: 9.5%;
      width: 94.4%;

      .el-select {
        width: 100%;
        height: 50px;
        margin-top: 15px;

        ::v-deep .el-input__inner {
          width: 100%;
          height: 50px;
        }
      }
      p {
        width: 86%;
        font-family: MicrosoftYaHei;
        font-size: 14px;
        color: #3e3c3c;
        letter-spacing: 0;
        margin-top: 35px;
      }
      .drapdiv {
        width: 100%;
        height: 50px;
        margin-top: 12px;
        background: #ffffff;
        border: 1px solid #dedcda;
        border-radius: 3px;
        border-radius: 3px;
      }
      .el-radio {
        margin-top: 13px;
        font-family: MicrosoftYaHei;
        font-size: 14px;
        color: #3e3c3c;
        letter-spacing: 0;
      }
    }
    .drawbodysecond {
      margin-left: 2.7%;
      margin-top: 9.5%;
      width: 94.4%;
      div {
        width: 100%;
        h1 {
          font-family: MicrosoftYaHei-Bold;
          font-size: 14px;
          color: #3e3c3c;
          letter-spacing: 0;
          font-weight: bolder;
        }
        input {
          width: 100%;
          outline: none;
          height: 50px;
          padding-left: 15px;
        }
      }
      p {
        font-size: 14px;
        color: #3e3c3c;
        letter-spacing: 0;
        line-height: 60px;
      }
      .el-radio {
        font-size: 14px;
        color: #3e3c3c;
        letter-spacing: 0;
        line-height: 40px;
      }
      .elradiogroup {
        margin-left: 40px;
        width: 95%;
      }
    }
  }
}
.drawerfoot {
  width: 94.4%;
  margin-left: 2.7%;
  .buttongroup1 {
    width: 100%;
    height: 100%;
    button {
      float: right;
      background: #2D6CFC;
      border-radius: 3px;
      border-radius: 3px;
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #ffffff;
      letter-spacing: 0;
      text-align: center;
      outline: none;
      width: 81px;
      height: 40px;
      margin-top: 24px;
    }
  }
  .buttongroup2 {
    width: 100%;
    height: 100%;
    outline: none;
    .buttone {
      float: left;
      background: #ffffff;
      border: 1px solid #dedcda;
      border-radius: 3px;
      border-radius: 3px;
      width: 87px;
      height: 40px;
      margin-top: 24px;
      font-size: 14px;
      color: #2D6CFC;
      letter-spacing: 0;
      text-align: center;
      outline: none;
    }
    .buttwo {
      float: right;
      background: #2D6CFC;
      border-radius: 3px;
      border-radius: 3px;
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #ffffff;
      letter-spacing: 0;
      text-align: center;
      outline: none;
      width: 81px;
      height: 40px;
      margin-top: 24px;
    }
  }
}
.exportportion {
  width: 100%;
  // margin-left: 2.7%;
  font-family: MicrosoftYaHei;
  font-size: 14px;
  color: #080707;
  letter-spacing: 0;
  h1 {
    font-size: 14px;
    margin: 10px 0 5px 0;
  }
  input {
    width: 100%;
    height: 30px;
    outline: none;
    background: #ffffff;
    border: 1px solid #dedcda;
    border-radius: 3px;
  }
  .el-select {
    width: 100%;
    height: 34px;
    background: #ffffff;
    border-radius: 3px;
  }
  ::v-deep .el-input__inner {
    width: 100%;
    height: 34px;
    text-align: left;
    color: #606266;
  }
  p {
    line-height: 24px;
  }
  div {
    b {
      color: #005fb2;
      cursor: pointer;
    }
  }
}
</style>
