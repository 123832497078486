/*
 * @Author: your name
 * @Date: 2022-02-16 15:27:55
 * @LastEditTime: 2022-03-11 09:54:11
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \lightning-web\src\views\marketForm\api.js
 */
import axios from '@/config/httpConfig'
// 获取form的基本信息
export function getFormInfo(data) {
    return axios.post('/form/getFormInfo', data)
}
//获取form的分享信息
export function getShareInfo(data) {
    return axios.post('/form/getShareInfo', data)
}
// 获取form列表
export function getFormList(data) {
    return axios.post('/form/getFormList', data)
}
//获取form的echarts折线图信息
export function getfromData(data) {
    return axios.post('/form/getfromData', data)
}
//获取form详细信息
export function getFormdetail(data) {
    return axios.post('/form/getFormDetail', data)
}

//保存form信息
export function saveForm(data) {
    return axios.post('/form/saveForm', data)
}
// 删除表单
export function deleteForm(data) {
    return axios.post('/form/deleteForm', data)
}
// 获取form的市场活动成员的字段设置
export function getFieldShow(data) {
    return axios.post('/form/getFieldShow', data)
}

// 获取form的潜在客户的字段设置
export function getFieldDefault(data) {
    return axios.post('/form/getFieldDefault', data)
}

// 保存表单最大提交量设置
export function saveFormSetting(data) {
    return axios.post('/form/saveFormSetting', data)
}

// 获取表单最大提交量设置
export function getFormSetting(data) {
    return axios.post('/form/getFormSetting', data)
}

// 获取form下载表单二维码
export function getSharePhoto(data) {
    return axios.post('/form/getSharePhoto', data)
}

//获取MailChimp权限
export function getMailChimpPermission(data) {
    return axios.post('/mailchimp/getMailChimpPermission', data)
}

// 获取MailChimp列表
export function getList(data) {
    return axios.post('/mailchimp/getList', data)
}

// 获取mailchimp认证跳转链接
export function getAuthUrl(data) {
    return axios.post('/mailchimp/getAuthUrl', data)
}

// 获取mailchimp创建市场活动
export function createCampaign(data) {
    return axios.post('/mailchimp/createCampaign', data)
}

// 获取创建mailchimp的content
export function createContent(data) {
    return axios.post('/mailchimp/createContent', data)
}